/*eslint-disable*/
import React from "react"

// @Mui components
import { styled } from "@mui/material/styles"

// core components
import LandingPage from "../LandingPageMaterial/Layout/LandingPage"
import Parallax from "components/material-kit-pro-react/components/Parallax/Parallax.js"
import GridContainer from "components/material-kit-pro-react/components/Grid/GridContainer.js"
import GridItem from "components/material-kit-pro-react/components/Grid/GridItem.js"
import Card from "components/material-kit-pro-react/components/Card/Card.js"
import CardBody from "components/material-kit-pro-react/components/Card/CardBody.js"
import loginPageStyle from "components/material-kit-pro-react/views/loginPageStyle"
import Register from "Portal/Views/Security/Register"
import { graphql, useStaticQuery } from "gatsby"


const NewDiv = styled("div")(({ theme }) => {
  const styles = loginPageStyle(theme)
  return { ...styles.container, ...styles.containerTopSeparation }
})

export default function RegisterPage() {
  

  const data = useStaticQuery(
    graphql`
      {
        allStrapiAssetsMedias: allStrapiAssetsMedia(
          filter: { codigo: { eq: "REGISTER_BACKGROUND" } }
        ) {
          edges {
            node {
              archivo {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 95, layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const backgroundImage =
    data.allStrapiAssetsMedias.edges[0].node.archivo.localFile.childImageSharp
    .gatsbyImageData.images.fallback.src

  React.useEffect(() => {
    window.scrollTo(0, 0)
    document.body.scrollTop = 0
  })


  return (
    <LandingPage pageTitle="Registro de Usuarios">
      <Parallax
        image={backgroundImage ? backgroundImage : null}
        className={loginPageStyle.parallax}
      >
        <NewDiv>
          <GridContainer justify="center">
            <GridItem xs={12} sm={8} md={6}>
              <Card>
                <CardBody>
                  <Register />
                </CardBody>
              </Card>
              <br></br>
            </GridItem>
          </GridContainer>
        </NewDiv>
      </Parallax>
    </LandingPage>
  )
}
