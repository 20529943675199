import React, { useState } from "react"
import { navigate } from "gatsby"

// @Mui components
import { styled } from "@mui/material/styles"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
//import Hidden from "@mui/material/Hidden"
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from '@mui/material/useMediaQuery';

//Core components
import Wizard from "components/Core/Wizard/Wizard"
import GridContainer from "components/material-dashboard-pro-react/components/Grid/GridContainer.js"
import GridItem from "components/material-dashboard-pro-react/components/Grid/GridItem.js"
import RegisterIdentification from "./RegisterIdentification"
import RegisterResult from "./RegisterResult"
import RegisterCredentials from "./RegisterCredentials"
import RegisterQuestions from "./RegisterQuestions"
import RegisterTerms from "./RegisterTerms"
import RegisterActivate from "./RegisterActivate"

const styles = {
  textCenter: {
    textAlign: "center",
  },
  floatRigth: {
    float: "right",
  },
  root: {
    flexGrow: 1,
    maxWidth: 600,
  },
  hideContent: {
    display: "none",
  },
  showContent: {
    display: "block",
  },
  dialogSize: {
    "@media (min-width: 60px)": {
      width: 330,
      height: 510,
      align: "center",
    },
    "@media (min-width: 760px)": {
      width: 550,
      height: 530,
    },
  },
  tab: {
    padding: "10px 10px 10px 10px !important",
    alignSelf: "center",
  },
}

const NewDialog = styled(Dialog)(({ theme, ownerState }) => {
  const { showModal } = ownerState
  return { ...(showModal ? styles.showContent : styles.hideContent) }
})

const NewIconButton = styled(IconButton)(({ theme }) => {
  return { ...styles.floatRigth }
})

const NewDialogContent = styled(DialogContent)(({ theme }) => {
  return { ...styles.tab }
})

const NewDialogContentText = styled(DialogContentText)(({ theme }) => {
  return { ...styles.dialogSize }
})

export default function Register() {
  const [openInbox, setOpenInbox] = useState(true)
  const [showModal, setShowModal] = useState(true)
  const [state, setState] = useState()
  const theme = useTheme()
  const hiddenMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const hiddenSmDown = useMediaQuery(theme.breakpoints.down("sm"))

  function finishRegister() {
    navigate("/")
  }

  function onClose() {
    setOpenInbox(false)
    setShowModal(false)
    finishRegister()
  }

  function onOpen() {
    setShowModal(true)
  }

  const windowGlobal = typeof window !== "undefined" && window
  const smallView = windowGlobal && window.innerWidth < 600

  function getRegisterWizard() {
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <Wizard
            validate
            steps={[
              {
                stepName: "Identificación",
                stepComponent: RegisterIdentification,
                stepId: "step_identification",
              },
              {
                stepName: "Credenciales",
                stepComponent: RegisterCredentials,
                stepId: "step_credential",
              },
              {
                stepName: "Preguntas de Seguridad",
                stepComponent: RegisterQuestions,
                stepId: "step_questions",
              },
              {
                stepName: "Términos y Condiciones",
                stepComponent: RegisterTerms,
                stepId: "step_terms",
              },
              {
                stepName: "Confirmar",
                stepComponent: RegisterResult,
                stepId: "step_result",
              },
              {
                stepName: "Finalizar",
                stepComponent: RegisterActivate,
                stepId: "step_activate",
              },
            ]}
            title=""
            subtitle={
              !smallView
                ? "Regístrate y disfruta de todos los servicios que te ofrecemos en nuestro portal."
                : "Regístrate y disfruta de nuestros servicios"
            }
            finishButtonClick={(e) => finishRegister(e)}
            smallView={true}
          />
        </GridItem>
      </GridContainer>
    )
  }

  return (
    <>
      {!hiddenMdUp && (
        //<Hidden mdUp>
        <NewDialog
          open={openInbox}
          fullScreen={true}
          ownerState={{
            showModal: showModal,
          }}
        >
          <DialogTitle id="alert-dialog-title">
            Autoregistro
            <NewIconButton onClick={onClose} >
              <CloseIcon />
            </NewIconButton>
          </DialogTitle>
          <NewDialogContent>
            <NewDialogContentText>
              {getRegisterWizard()}
            </NewDialogContentText>
          </NewDialogContent>
        </NewDialog>
        //</Hidden>
      )}
      {/*<Hidden smDown>*/}
      {!hiddenSmDown && getRegisterWizard()}
      {/*</Hidden>*/}
    </>
  )
}
