import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react"
import { useForm } from "react-hook-form"
import { useDialog } from "context/DialogContext"
import GridContainer from "components/material-dashboard-pro-react/components/Grid/GridContainer.js"
import GridItem from "components/material-dashboard-pro-react/components/Grid/GridItem.js"
import PasswordController from "components/Core/Controller/PasswordController"
import PasswordConfirmController from "components/Core/Controller/PasswordConfirmController"
import { useLocation } from "@reach/router"
import { useLoading } from "context/LoadingContext"
import { initAxiosInterceptors } from "utils/axiosConfig"
import UserNameController from "components/Core/Controller/UserNameController"

const RegisterCredentials = forwardRef((props, ref) => {
  //const { triggerValidation, getValues, ...objForm } = useForm();

  const defaultValues = {
    p_username: '',
    p_password: '',

  }
  const methods = useForm({ defaultValues })
  const {
    trigger,
    getValues,
    ...objForm
  } = methods

  const data = props.allStates.step_identification
  const [state, setState] = useState()
  const [name, setName] = useState(null)
  const [email, setEmail] = useState(null)
  const [cellPhone, setCellPhone] = useState(null)
  const [isInsured, setIsInsured] = useState(null)
  const [userName, setUserName] = useState(null)
  const dialog = useDialog()
  const location = useLocation()
  const prefixPathSite = process.env.GATSBY_PREFIX_SITE
    ? process.env.GATSBY_PREFIX_SITE
    : ""
  const loading = useLoading()

  useEffect(() => {
    data && setName(data.name)
    data && setEmail(data.email ? data.email.trim() : null)
    data && setCellPhone(data.cellphone ? data.cellphone.trim() : null)
    data && setIsInsured(data.profile_id == 6 ? true : false)
    data && data.hasUserCreatedNotActivated == "S" && setUserName(data.username)
  }, [props])

  useEffect(() => {
    email && objForm.setValue("p_email", email)
    cellPhone && objForm.setValue("p_phone", cellPhone)
  }, [])

  useEffect(() => {
    initAxiosInterceptors(dialog, loading)
  }, [])

  useImperativeHandle(ref, () => ({
    isValidated(postValidate) {
      trigger()
        .then((result) => {
          if (result) {
            const values = getValues()
            //console.log(values)
            setRegister(values, postValidate)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },
    sendState() {
      return state
    },
  }))

  async function setRegister(dataform, postFnc) {
    const url = location.origin + prefixPathSite + "/" + "valida_registro?id="
    let phoneBase = ""
    let mailBase = ""
    const userNameBase = userName ? userName : dataform.p_username

    phoneBase = cellPhone
    mailBase = email

    const areaCode =
      phoneBase && phoneBase.length > 4 ? phoneBase.substring(0, 4) : undefined
    const phoneNumber =
      phoneBase && phoneBase.length > 4
        ? phoneBase.substring(4, phoneBase.length)
        : undefined

    const params = {
      Profiles: [{ Profile_id: data.profile_id }],
      principal_profile: data.profile_id,
      tipoid: data.tipoid,
      numid: data.numid,
      dvid: data.dvid,
      first_name: data.first_name,
      last_name: data.last_name,
      email: mailBase,
      phone_number: phoneNumber ? parseInt(phoneNumber) : phoneNumber,
      area_code: areaCode ? parseInt(areaCode) : areaCode,
      portal_user: userNameBase,
      pwd: dataform.p_password,
      core_user: "",
      url_validate_register_page: url,
    }

    setState(params)
    postFnc(params)
  }

  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={8}>
        <form>
          <h5>{name}</h5>
          <h6>Email: {email}</h6>
          {cellPhone && <h6>Phone: {cellPhone}</h6>}

          <h6>Usuario: {userName}</h6>

          {!userName && (
            <UserNameController
              objForm={objForm}
              label="Usuario"
              name="p_username"
            />
          )}
          <PasswordController objForm={objForm} label="Clave" />
          <PasswordConfirmController
            objForm={objForm}
            label="Confirmar clave"
          />
        </form>
      </GridItem>
    </GridContainer>
  )
})
export default RegisterCredentials
